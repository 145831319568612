<template>
  <nav class="nav nav-pills flex-column mb-5">
    <router-link
      :to="{
        name: 'PublicHome',
      }"
      :class="{ active: isActive('/') }"
      class="nav-link border-bottom border-primary"
      ><i class="bi bi-house-fill me-2"></i>ホーム</router-link
    >
    <router-link
      :to="{
        name: 'PublicProgramList',
      }"
      :class="{ active: isActive('/programs') }"
      class="nav-link border-bottom border-primary"
      ><i class="bi-collection-play-fill me-2"></i>番組一覧</router-link
    >
    <router-link
      :to="{
        name: 'PublicProgramTimetable',
      }"
      :class="{ active: isActive('/timetable') }"
      class="nav-link border-bottom border-primary"
      ><i class="bi bi-calendar-week me-2"></i>番組表</router-link
    >
    <router-link
      :to="{
        name: 'PublicNotifyEnded',
      }"
      :class="{ active: isActive('/notify') }"
      class="nav-link border-bottom border-primary"
      ><i class="bi bi-bell-fill me-2"></i>通知一覧</router-link
    >
  </nav>
  <ul>
    <li>
      <router-link
        :to="{
          name: 'PublicPageDetail',
          params: { permalink: 'terms' },
        }"
        >利用規約</router-link
      >
    </li>
    <li>
      <router-link
        :to="{
          name: 'PublicPageDetail',
          params: { permalink: 'privacy' },
        }"
        >プライバシーポリシー</router-link
      >
    </li>
    <li>
      <a href="https://mktsystem.co.jp/company" target="_blank"
        >運営会社<i class="bi bi-box-arrow-up-right ms-1"></i
      ></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SideMenuContent',

  methods: {
    isActive(path) {
      return this.$route.path === path
    },
  },
}
</script>
