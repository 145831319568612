<template>
  <main class="position-absolute top-50 start-50 translate-middle">
    <div class="text-center">
      <div class="mb-3">
        <AppLogo
          svg-width="40%"
          svg-height="auto"
          stroke-color="#fff"
          fill-color="#fff"
        />
      </div>
      <div>
        LINE Notify 終了に伴い、通知サービスは終了しました。<br />
        ご利用いただきありがとうございました。
        <router-link
          :to="{
            name: 'PublicHome',
          }"
          class="w-100 mt-2 btn btn-link link-secondary"
          >戻る</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'NotifyEnded',
  components: {
    AppLogo,
  },
}
</script>
