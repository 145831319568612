import PublicLayout from '@/layouts/Public.vue'
import PublicHome from '@/pages/public/Home.vue'
import PublicNotifyEnded from '@/pages/public/NotifyEnded.vue'
import PublicPageDetail from '@/pages/public/PageDetail.vue'
import PublicProgramDetail from '@/pages/public/ProgramDetail.vue'
import PublicProgramList from '@/pages/public/ProgramList.vue'
import PublicProgramTimetable from '@/pages/public/ProgramTimetable.vue'

export default {
  routes: [
    {
      path: '/',
      component: PublicLayout,
      name: 'PublicLayout',
      children: [
        {
          path: '',
          component: PublicHome,
          name: 'PublicHome',
        },
        {
          path: '/programs',
          component: PublicProgramList,
          name: 'PublicProgramList',
        },
        {
          path: '/p/:permalink([a-z0-9-]+)',
          component: PublicProgramDetail,
          name: 'PublicProgramDetail',
          props: (route) => ({ permalink: route.params.permalink }),
        },
        {
          path: '/pages/:permalink([a-z0-9-]+)',
          component: PublicPageDetail,
          name: 'PublicPageDetail',
          props: (route) => ({ permalink: route.params.permalink }),
        },
        {
          path: '/timetable',
          component: PublicProgramTimetable,
          name: 'PublicProgramTimetable',
        },
      ],
    },
    {
      path: '/notify',
      component: PublicNotifyEnded,
      name: 'PublicNotifyEnded',
    },
  ],
}
